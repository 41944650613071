import React, { useState } from "react";
import {
  Decriment,
  Increment,
  UpArrow,
  ZoomOut,
} from "../../../assets/svg/AllSvg";
import { Upload } from "../../../assets/images";
import FileViewPopup from "./FileViewPopup";

const PackageDetails = ({ pkg, counting, SetCounting }) => {
  const [isListVisible, setIsListVisible] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [count, setCount] = useState(0);

  const handleIncrement = () => {
    setCount(count + 1);
    SetCounting([{ ...counting, count: count + 1 }]);
  };
  const handleDecrement = () => {
    if (count > 0) setCount(count - 1);
  };
  const handleZoomOutClick = () => setIsPopupVisible(true);
  const closePopup = () => setIsPopupVisible(false);

  if (!pkg) {
    return <div>No package details available.</div>;
  }

  return (
    <div
      className={`p-4 ${
        count > 0 ? "bg-lightPeach" : "bg-white"
      } transition-colors duration-300`}
    >
      <div className="sm:flex gap-5 ">
        <div className="relative w-1/2 ">
          <img className="object-cover" src={Upload} alt="Package" />
          <div
            className="absolute top-0 right-0 xl:right-4 cursor-pointer"
            onClick={handleZoomOutClick}
          >
            <ZoomOut />
          </div>
        </div>
        <div className="w-full sm:mt-0 mt-2">
          <div className="uppercase sm:text-base text-xs text-black font-normal">
            {pkg.title}
          </div>
          <p className="block mt-1 sm:text-lg text-sm font-normal text-darkRed">
            {pkg.price}
          </p>
          <div className="flex items-center mt-4">
            <div className="cursor-pointer" onClick={handleDecrement}>
              <Decriment />
            </div>
            <span className="mx-4 sm:text-lg text-base">{count}</span>
            <div className="cursor-pointer" onClick={handleIncrement}>
              <Increment />
            </div>
          </div>

          <div className="mt-4 border-t border-t-grey border-b border-b-grey py-2">
            <div className="flex justify-between items-center">
              <span className="text-black">Description</span>
              <button onClick={() => setIsListVisible(!isListVisible)}>
                <UpArrow
                  className={`transform transition-transform duration-300 ${
                    isListVisible ? "rotate-180" : "rotate-0"
                  }`}
                />
              </button>
            </div>
            {isListVisible && (
              <>
                <p className="sm:text-sm text-xs">{pkg.description}</p>
                <div className="mt-2 sm:text-base text-xs">
                  <p>Items:</p>
                  <ul>
                    {pkg.items.map((item, idx) => (
                      <li key={idx} className="list-disc ml-6">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {isPopupVisible && <FileViewPopup onClose={closePopup} />}
    </div>
  );
};

export default PackageDetails;
