import React from "react";
import { Logo, LogoText } from "../assets/images";

const Header = () => {
  return (
    <>
      <header className="sticky top-0 left-0 w-full z-[9]">
        <div className="shadow-md bg-maintheme p w-full p-3">
          <div className="flex gap-2 cursor-pointer">
            <span>
              <div className="text-white flex items-center">
                <img src={Logo} className="h-14" />
                <img src={LogoText} className="h-14" />
              </div>
            </span>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
