import React from "react";
import { Upload } from "../../../assets/images";
import { FillColse } from "../../../assets/svg/AllSvg";

const FileViewPopup = ({ onClose }) => {
  return (
    <>
      <div className="model">
        <div className="relative p-5 ">
          <div className=" w-full">
            <img src={Upload} alt="" className="w-[30rem] object-cover" />
            {/* Close button */}
            <button onClick={onClose} className="absolute top-0 right-0 ">
              <FillColse />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileViewPopup;
