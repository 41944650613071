import React from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import { BackGround } from "../assets/images";

const MainLayout = () => {
  return (
    <div>
      <div
        className={` h-full w-full  bg-no-repeat bg-cover object-cover bg-center `}
        style={{
          backgroundImage: `url(${BackGround})`,
        }}
      >
        <Header />
        <div>
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
