import React, { useContext, useState } from "react";
import { CartVector, CloseVector } from "../../../assets/svg/AllSvg";
import { CheckBox } from "../../../components/micro";
import PackageDetails from "./PackageDetails";
import SelectDetails from "./SelectDetails";
import layoutContext from "../../../layouts/layoutContext";

const SelectPackege = () => {
  const { prev, next } = useContext(layoutContext);
  const [counting, SetCounting] = useState([]);

  const packages = [
    {
      title: "Lion Dance - Golden Package A",
      price: "$1688",
      description:
        "Norem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan",
      items: ["1 x ABCD", "1 x ABCD"],
    },
    {
      title: "Lion Dance - Silver Package B",
      price: "$1288",
      description:
        "Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.",
      items: ["2 x XYZ", "1 x ABCD"],
    },
  ];

  return (
    <div className="grid sm:grid-cols-3 grid-rows items-start gap-2 my-3">
      <div className="bg-white rounded-2xl text-black py-5 px-6 sm:col-span-2">
        <div>
          <div className="flex items-center gap-3">
            <CartVector />
            <p className="text-maintheme md:text-2xl text-base font-normal">
              Yi Wei’s Packages
            </p>
          </div>
          <div className="mb-5">
            <p className="sm:text-sm text-xs font-normal">
              Norem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus.
            </p>
          </div>
          <div className="space-y-3 max-h-[500px] overflow-auto ">
            {packages?.length > 0 ? (
              packages?.map((pkg, index) => (
                <div className="card">
                  <PackageDetails
                    key={index}
                    pkg={pkg}
                    counting={counting}
                    SetCounting={SetCounting}
                  />
                </div>
              ))
            ) : (
              <p>No packages available.</p>
            )}
          </div>
        </div>
      </div>
      <SelectDetails next={next} />
    </div>
  );
};

export default SelectPackege;
