import React, { useState } from "react";
import { CheckCircle } from "../../../assets/svg/AllSvg";
import { Provider } from "../../../layouts/layoutContext";
import SelectPackege from "./SelectPackege";
import SelectDateTime from "./SelectDateTime";
import Contact from "./Contact";
import ViewQuatation from "./ViewQuatation";

const BookPackage = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const tabLabel = [
    { value: "select_package", label: "Select Package" },
    { value: "date_time", label: "Select Date & Time" },
    { value: "contact_information", label: "Contact Information" },
    { value: "view_quotation", label: "View Quotation" },
  ];

  const renderStep = (step) => {
    switch (
      tabLabel[step]?.value // added optional chaining to prevent undefined access
    ) {
      case "select_package":
        return <SelectPackege />;
      case "date_time":
        return <SelectDateTime />;
      case "contact_information":
        return <Contact />;
      case "view_quotation":
        return <ViewQuatation />;
      default:
        return null;
    }
  };

  const next = () => {
    if (currentStep < tabLabel.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  return (
    <>
      <div className="container mx-auto px-4">
        <Provider value={{ next, prev }}>
          <div className="bg-white my-2 p-4 rounded-2xl">
            <div className="stepper  flex sm:flex-row flex-col sm:justify-between sm:items-center sm:gap-0 gap-4 ">
              {tabLabel?.map((step, index) => (
                <div
                  key={index}
                  className="flex items-center gap-2 sm:text-left text-center"
                >
                  <span
                    className={`xl:max-w-8 max-w-6 xl:min-w-8 min-w-6 w-full xl:h-8 h-6 rounded-full flex justify-center items-center bg-meduimGrey text-white xl:text-base sm:text-sm text-xs ${
                      index < currentStep ? "!bg-maintheme " : ""
                    }${index === currentStep ? "!bg-maintheme" : ""}`}
                  >
                    {index < currentStep ? (
                      <CheckCircle circleFill="#fff" />
                    ) : (
                      index + 1
                    )}
                  </span>
                  <p
                    className={`xl:text-base  text-xs   text-black ${
                      index < currentStep ? "text-black" : ""
                    } ${index === currentStep ? "font-semibold" : ""}`}
                  >
                    {step.label}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div>{renderStep(currentStep)}</div>
        </Provider>
      </div>
    </>
  );
};

export default BookPackage;
