import React, { useContext } from "react";
import { ArrowBack } from "../../../assets/svg/AllSvg";
import { Logo } from "../../../assets/images";
import { DefaultButton } from "../../../components/micro";
import { useNavigate } from "react-router-dom";
import layoutContext from "../../../layouts/layoutContext";

const ViewQuatation = () => {
  const { prev, next } = useContext(layoutContext);

  const navigate = useNavigate();
  const quotationData = [
    {
      serial: "A",
      itemPackage: "Lion Dance Performance (Date: 07/02/25 Time: 11am)",
      description: [
        "Golden Package A Includes:",
        "X 02 Golden Lion c/w 'Traditional Caiqing' performance with Scrolls.",
        "X 01 Blessing of office areas as per request.",
        "Cal Qing items e.g. plates, oranges, and lettuce etc will be provided.",
      ],
      amount: "$1,888.00",
    },
    {
      serial: "B",
      itemPackage: "Lion Dance Performance (Date: 07/02/25 Time: 11am)",
      description: [
        "Golden Package B Includes:",
        "X 02 Golden Lion c/w 'Traditional Caiqing' performance with Scrolls.",
        "X 01 God of Fortune Mascot.",
        "X 01 Blessing of office areas as per request.",
        "Cal Qing items e.g. plates, oranges, and lettuce etc will be provided.",
      ],
      amount: "$1,888.00",
    },
    {
      serial: "C",
      itemPackage: "Lion Dance Performance (Date: 07/02/25 Time: 11am)",
      description: [
        "Golden Package C Includes:",
        "X 02 Golden Lion c/w 'Traditional Caiqing' performance with Scrolls.",
        "X 01 God of Fortune Mascot.",
        "X 02 Single Highpole (4m high) performance.",
        "X 01 Blessing of office areas as per request.",
        "Cal Qing items e.g. plates, oranges, and lettuce etc will be provided.",
      ],
      amount: "$2,888.00",
    },
    {
      serial: "D",
      itemPackage: "Additional Special Request",
      description: [
        "We will contact you shortly to know more about the special request you would like to have. After which we will quote you accordingly.",
      ],
      amount: "To Be Advised",
    },
  ];
  return (
    <>
      <div className="bg-white rounded-2xl text-black py-5 sm:px-6  col-span-2 space-y-2 my-3">
        <div className="cursor-pointer ">
          <button onClick={() => prev()} className="flex gap-2 items-center">
            <ArrowBack />
            <p>Back</p>
          </button>
        </div>
        <div className="shadow sm:p-10 p-2 mt-2">
          <div className="grid lg:grid-cols-4">
            <div className="col-span-3">
              <img src={Logo} alt="" />
            </div>
            <div className="flex flex-col gap-2">
              <p className="sm:text-xl text-base font-normal font-poppins ">
                {" "}
                Yi Wei Athletic Association{" "}
              </p>
              <div className="sm:text-sm text-xs font-normal text-secondary_black">
                <p>Blk 446 Hougang ave 8 #B1-1635 Singapore 530446 </p>
                <p>Tel:6489 5766 Fax:6487 6276 Hp:9272 0272 </p>
                <p>Website: www.yiwei.com.sg/ Email: enquiry@yiwei.com.sg</p>
              </div>
            </div>
          </div>
          <div className="my-2">
            <p className="sm:text-2xl text-xl font-normal">Quotation</p>
          </div>
          <div className="flex items-center justify-between">
            <div className="sm:text-sm text-xs font-normal txet-secondary_black">
              <p>Quotation ID: 004/YW/2025</p>
              <p>Date: 17/07/2023</p>
            </div>
            <div>
              <p className="sm:text-sm text-xs font-normal txet-secondary_black text-end">
                Total Amount:
              </p>
              <p className="lg:text-4xl sm:2xl text-base text-maintheme font-normal">
                $12,040.00
              </p>
            </div>
          </div>
          <div>
            {/* table */}
            <table className="table w-full mt-5 ">
              <thead>
                <tr className="text-left  bg-lightPeach ">
                  <th className="p-4  sm:text-sm text-xs font-medium">S/N</th>
                  <th className="p-4  sm:text-sm text-xs font-medium">
                    Item & Package
                  </th>
                  <th className="p-4 sm:text-sm text-xs font-medium">Amount</th>
                </tr>
              </thead>
              <tbody>
                {quotationData.map((item, index) => (
                  <tr key={index} className="border-b overflow-auto">
                    <td className="p-4 ">{item.serial}</td>
                    <td className="p-4 flex flex-col gap-3">
                      <p className="text-sm lg:text-base  font-normal ">
                        {item.itemPackage}
                      </p>
                      <ul className="list-none">
                        {item.description.map((desc, idx) => (
                          <li
                            key={idx}
                            className="sm:text-sm lg:text-base text-xs font-normal"
                          >
                            {desc}
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td className="p-2 sm:text-base text-sm">{item.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* terms condition */}
          <div className="mt-6 sm:flex items-start justify-between">
            <div className="text-xs">
              <p>TERMS AND CONDITIONS:</p>
              <p>
                *30 days credit payment terms upon completion of performance.
              </p>
              <p>
                *50% of agreed price will be charged upon cancellation after
                confimation.
              </p>
              <p>
                *In the event of performance cancellation due to wet weather,no
                refunds or discounts will be provided.
              </p>
              <p>
                *In the event of performance stunt mistakes,no refunds or
                discounts will be provided.
              </p>
              <p>
                *Quotation is only valid for X07 days subjected to our schedule
                availability.
              </p>
              <p>Thank you.</p>
            </div>
            <div className="text-sm font-normal text-#000000 ">
              <p>Subtotal: $12,040.00</p>
              <p>Deposit: -</p>
              <p>Total: $12,040.00</p>
            </div>
          </div>
          {/* Signature */}
          <div className="sm:flex justify-between mt-5">
            <div className="sm:w-1/4 w-44">
              <div>
                <p className="text-primaryblack">Yours Truly,</p>
              </div>
              <div className="p-4">sign</div>
              <div className="border-t text-sm text-primaryblack">
                <p>Marcus Tock</p>
                <p>Yiwei Athletic Association </p>
                <p>Hp: 9999 222</p>
              </div>
            </div>
            <div className="sm:w-1/4 w-44 sm:mt-0 mt-3">
              <div>
                <p className="text-primaryblack">Confirmed by,</p>
              </div>
              <div className="p-4">sign</div>
              <div className="border-t text-sm text-primaryblack">
                <p>Signature & Date</p>
                <p>Name: </p>
                <p>Designation</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end mr-2">
          <DefaultButton value="Accept" onClick={() => navigate("/message")} />
        </div>
      </div>
    </>
  );
};

export default ViewQuatation;
