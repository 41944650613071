import React, { useContext, useState } from "react";
import SelectDetails from "./SelectDetails";
import PackageDetails from "./PackageDetails";
import {
  ArrowBack,
  DateCalender,
  DownFillArrow,
} from "../../../assets/svg/AllSvg";
import ReactDatePicker from "react-datepicker";
import layoutContext from "../../../layouts/layoutContext";

const SelectDateTime = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [counting, SetCounting] = useState([]);
  const { prev, next } = useContext(layoutContext);

  const packages = [
    {
      title: "Lion Dance - Golden Package A",
      price: "$1688",
      description:
        "Norem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan",
      items: ["1 x ABCD", "1 x ABCD"],
    },
    {
      title: "Lion Dance - Silver Package B",
      price: "$1288",
      description:
        "Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.",
      items: ["2 x XYZ", "1 x ABCD"],
    },
  ];
  return (
    <>
      <div className="grid sm:grid-cols-3 grid-rows items-start gap-2 my-3">
        <div className="bg-white rounded-2xl text-black py-5 px-6 sm:col-span-2">
          <div>
            <div className="cursor-pointer ">
              <button
                onClick={() => prev()}
                className="flex gap-2 items-center"
              >
                <ArrowBack />
                <p>Back</p>
              </button>
            </div>
            <div className="flex items-center gap-3">
              <DateCalender />
              <p className="text-maintheme md:text-2xl text-base font-normal">
                Choose Your Preferred Date & Time
              </p>
            </div>
            <div>
              <p className="sm:text-sm text-xs font-normal mb-2">
                Choose your preferred Date & Time. We will contact you
                seperately if the time slots are not longer available.
              </p>
            </div>
            <div className="space-y-3 max-h-[500px] overflow-auto ">
              {packages?.length > 0 ? (
                packages?.map((pkg, index) => (
                  <div className="card">
                    <PackageDetails
                      key={index}
                      pkg={pkg}
                      counting={counting}
                      SetCounting={SetCounting}
                    />
                    {/* Date */}
                    <div className="px-4">
                      <p className="sm:text-base text-sm text-primaryblack">
                        Please Select Your Preferred Date & Time
                      </p>
                      <div className=" max-w-3xl py-2 focus-within:border-themeBtn hover:border-maintheme ">
                        <ReactDatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          timeCaption="Select a Time"
                          id={`date-${index}`}
                          dateFormat="dd/MM/yyyy | hh:mm aa"
                          showTimeSelect
                          placeholderText="Select Date"
                          className="w-full border border-gray-300 rounded-md px-3 py-3 focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No packages available</p>
              )}
            </div>
          </div>
        </div>
        <SelectDetails next={next} />
      </div>
    </>
  );
};

export default SelectDateTime;
