import React from "react";
import { BackGround } from "../../assets/images";
import BookPackage from "./package/BookPackage";

const Home = () => {
  return (
    <>
      <div className={` overflow-hidden relative`}>
        <div
        // className={` h-full w-full  bg-no-repeat bg-cover object-cover bg-center `}
        // style={{
        //   backgroundImage: `url(${BackGround})`,
        // }}
        >
          <div className="container mx-auto px-4 py-8">
            <p className="lg:text-[96px] sm:text-6xl text-2xl text-white font-light text-start pt-8 ">
              Booking Details
            </p>
            <p className="sm:text-base text-sm text-white font-normal mt-2">
              Place a booking here, and fill out all the forms to proceed on...
            </p>
          </div>
        </div>
        <div className="text-white">
          <BookPackage />
        </div>
      </div>
    </>
  );
};

export default Home;
