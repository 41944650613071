import React from "react";
import { DefaultButton } from "../../../components/micro";
import { BackGround } from "../../../assets/images";
import { useNavigate } from "react-router-dom";

const MessagePopup = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={` overflow-hidden relative`}>
        <div
          className={` h-full w-full  bg-no-repeat bg-cover object-cover bg-center `}
          style={{
            backgroundImage: `url(${BackGround})`,
          }}
        >
          <div className="flex items-center  min-h-[calc(100vh-128px)]  bg-center">
            <div className="bg-white bg-opacity-75 sm:p-8 p-4 rounded-lg  shadow-lg text-center max-w-2xl sm:mx-auto mx-4">
              <h1 className="sm:text-6xl text-3xl font-light mb-2">
                Thank you!
              </h1>
              <p className="sm:text-lg text-sm mb-3">
                "Name", we have received your inquiry and will be in touch with
                you shortly!
              </p>
              <DefaultButton
                value="back to homepage"
                onClick={() => navigate("/")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessagePopup;
