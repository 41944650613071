import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import DefaultLayout from "../layouts";
import Home from "../pages/home";
import MessagePopup from "../pages/home/package/MessagePopup";
import Page404 from "../common/Page404";
import Page500 from "../common/Page500";
const AppRoutes = () => {
  return (
    <>
      <ToastContainer position="bottom-right" theme="colored" />
      <Routes>
        {/* DefaultLayout */}
        <Route path="/" element={<DefaultLayout />}>
          {/* Home */}
          <Route index exact element={<Home />} />
          <Route path="/message" element={<MessagePopup />} />

          <Route
            exact
            path="*"
            name="Page 404"
            status={404}
            element={<Page404 />}
          />
          {/* Page 500 */}
          <Route
            exact
            path="/500"
            name="Page 500"
            status={500}
            element={<Page500 />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
