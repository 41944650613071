import React, { useContext, useState } from "react";
import { ArrowBack, ContactVector } from "../../../assets/svg/AllSvg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  InputError,
  InputWithLabel,
  SelectBox,
} from "../../../components/micro";
import layoutContext from "../../../layouts/layoutContext";
import SelectDetails from "./SelectDetails";
const Contact = () => {
  const { prev, next } = useContext(layoutContext);

  const countryArray = [
    { id: "91", name: "91" },
    { id: "92", name: "92" },
  ];
  return (
    <>
      <div className="grid sm:grid-cols-3 grid-rows items-start gap-2 my-3">
        <div className="bg-white rounded-2xl text-black py-5 px-6 sm:col-span-2">
          <div className="cursor-pointer ">
            <button onClick={() => prev()} className="flex gap-2 items-center">
              <ArrowBack />
              <p>Back</p>
            </button>
          </div>
          <div className="flex items-center gap-3 mt-2">
            <ContactVector />
            <p className="text-maintheme md:text-2xl text-base font-normal">
              Contact Information
            </p>
          </div>
          <div>
            <p className="sm:text-sm text-xs font-normal">
              Let us know the billing information
            </p>
          </div>
          <div className="pt-3">
            <Formik
              enableReinitialize={true}
              initialValues={{
                fullName: "",
                companyName: "",
                email: "",
                gender: "",
                dob: "",
                phone: "",
                officeNumber: "",
                country: "",
                address: "",
              }}
              validationSchema={Yup.object().shape({
                fullName: Yup.string().min(3).required("Please Enter Name"),
                email: Yup.string().min(3).required("Please Enter E-mail"),
                address: Yup.string().min(3).required("Please Enter Address"),
                postalcode: Yup.string()
                  .required("Please Enter Postal Code")
                  .length(5, "Postal Code must be exactly 5 characters")
                  .matches(/^\d+$/, "Postal Code must be numeric"),
                phone: Yup.string()
                  .required("please Enter Mobile")
                  .max(10, "Mobile is too long - should be 10 chars maximum."),
                officeNumber: Yup.string()
                  .required("please Enter Mobile")
                  .max(10, "Mobile is too long - should be 10 chars maximum."),
                country: Yup.object().required("Please Select Country"),
              })}
              onSubmit={(values, { resetForm }) => {}}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="flex flex-col gap-2 mb-4">
                    <div className="mb-3">
                      {/* Name */}
                      <InputWithLabel
                        className={`${"text-black border hover:border-themeBtn"}  ${
                          errors.fullName && touched.fullName
                            ? `border border-red`
                            : `border border-borderTheme/80 focus-within:border-themeBtn `
                        }  transition duration-300 ease-in-out`}
                        idFromName="fullName"
                        label="Name*"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fullName}
                        errors={errors.fullName && touched.fullName}
                        errorsText={errors.fullName}
                      />
                    </div>
                    {/* compnay name */}
                    <div>
                      <InputWithLabel
                        className={`${"text-black border hover:border-themeBtn"}  ${`border border-borderTheme/80 focus-within:border-themeBtn `}  transition duration-300 ease-in-out`}
                        idFromName="companyname"
                        label="Company Name (If Have)"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.companyName}
                      />
                    </div>
                    <div className="grid lg:grid-cols-2 gap-4 mt-2">
                      <div className="grid grid-cols-3 gap-2">
                        {/* Country */}
                        <div>
                          <div
                            className={`  ${
                              errors.country && touched.country
                                ? ` border-red border`
                                : ` border-borderTheme/80`
                            } relative bg-white transition duration-300 rounded plus-number-inputs`}
                          >
                            <SelectBox
                              id="country"
                              placeholder="Country"
                              options={countryArray}
                              getOptionValue={(countryArray) => countryArray.id}
                              value={
                                countryArray?.length > 0 &&
                                countryArray?.find((op) => {
                                  return op.id === values?.country?.id;
                                })
                              }
                              onChange={(e) => {
                                setFieldValue("country", e);
                                setFieldValue("countryId", e?.id);
                              }}
                            />
                            <label
                              htmlFor="country"
                              className="absolute text-primaryblack pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2  origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                            >
                              Country
                            </label>
                          </div>
                          {errors.country && touched.country ? (
                            <InputError errorTitle={errors.country} />
                          ) : null}
                        </div>
                        {/*office Phone */}
                        <div className="col-span-2">
                          <InputWithLabel
                            className={`${"text-black border hover:border-themeBtn"}  ${
                              errors.officeNumber && touched.officeNumber
                                ? `border border-red`
                                : `border border-borderTheme/80 focus-within:border-themeBtn `
                            }  transition duration-300 ease-in-out`}
                            idFromName="officenumber"
                            label="Office Number"
                            type={"number"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.officeNumber}
                            errors={errors.officeNumber && touched.officeNumber}
                            errorsText={errors.officeNumber}
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-3 gap-2">
                        {/* Country */}
                        <div>
                          <div
                            className={`  ${
                              errors.country && touched.country
                                ? ` border-red border`
                                : ` border-borderTheme/80`
                            } relative bg-white transition duration-300 rounded plus-number-inputs`}
                          >
                            <SelectBox
                              id="country"
                              placeholder="Country"
                              options={countryArray}
                              getOptionValue={(countryArray) => countryArray.id}
                              value={
                                countryArray?.length > 0 &&
                                countryArray?.find((op) => {
                                  return op.id === values?.country?.id;
                                })
                              }
                              onChange={(e) => {
                                setFieldValue("country", e);
                                setFieldValue("countryId", e?.id);
                              }}
                            />
                            <label
                              htmlFor="country"
                              className="absolute text-primaryblack pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2  origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                            >
                              Country
                            </label>
                          </div>
                          {errors.country && touched.country ? (
                            <InputError errorTitle={errors.country} />
                          ) : null}
                        </div>
                        {/* Phone */}
                        <div className="col-span-2">
                          <InputWithLabel
                            className={`${"text-black border hover:border-themeBtn"}  ${
                              errors.phone && touched.phone
                                ? `border border-red`
                                : `border border-borderTheme/80 focus-within:border-themeBtn `
                            }  transition duration-300 ease-in-out`}
                            idFromName="phone"
                            label="Mobile"
                            type={"number"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                            errors={errors.phone && touched.phone}
                            errorsText={errors.phone}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Email */}
                    <div className="mb-3">
                      <InputWithLabel
                        className={`${"text-black border hover:border-themeBtn"}  ${
                          errors.email && touched.email
                            ? `border border-red`
                            : `border border-borderTheme/80 focus-within:border-themeBtn `
                        }  transition duration-300 ease-in-out`}
                        idFromName="email"
                        label="Email*"
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        errors={errors.email && touched.email}
                        errorsText={errors.email}
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                      {/* Address */}
                      <InputWithLabel
                        className={` ${
                          errors.address && touched.address
                            ? `border border-red`
                            : `border border-borderTheme/80 focus-within:border-themeBtn `
                        } transition duration-300 ease-in-out`}
                        idFromName="address"
                        label="Address*"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                        errors={errors.address && touched.address}
                        errorsText={errors.address}
                      />
                      {/* Postal Code */}
                      <InputWithLabel
                        className={` ${
                          errors.postalcode && touched.postalcode
                            ? `border border-red`
                            : `border border-borderTheme/80 focus-within:border-themeBtn `
                        } transition duration-300 ease-in-out`}
                        idFromName="postalcode"
                        label="Postal Code*"
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.postalcode}
                        errors={errors.postalcode && touched.postalcode}
                        errorsText={errors.postalcode}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <SelectDetails next={next} />
      </div>
    </>
  );
};

export default Contact;
