import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import "./assets/css/base.css";
//toas.css
import "react-toastify/dist/ReactToastify.css";
// date picker
import "react-datepicker/dist/react-datepicker.css";
const App = () => {
  return (
    <>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </>
  );
};

export default App;
