import React, { useState } from "react";
import { AddPrimaryButton } from "../../../components/micro";
import {
  Calender,
  Close,
  Handshake,
  SelectVector,
} from "../../../assets/svg/AllSvg";

const SelectDetails = ({ next, count }) => {
  const [isSelected, SetIsSelected] = useState();
  return (
    <>
      <div className="bg-light_grey rounded-2xl text-black py-5 px-6">
        <div>
          <div className="flex items-center gap-2">
            <SelectVector />
            <p className="lg:text-xl  text-sm font-normal text-maintheme">
              {" "}
              Selected package {count}
            </p>
          </div>
          <div className="p-2 bg-white rounded-lg mt-2">
            <div className="text-maintheme flex items-center justify-between p-1 bg-primarylightRed rounded">
              <p className="lg:text-xl text-base">Total: </p>
              <p className="lg:text-3xl sm:text-xl">$0.00</p>
            </div>
            <div className="mt-2">
              {isSelected ? (
                <>
                  <div className="flex justify-between items-center border-b border-b-primaryGrey">
                    <div className="flex items-center gap-2 ">
                      <p> 1.</p>
                      <div>
                        <p className="lg:text-2xl text-base font-normal text-maintheme">
                          price
                        </p>
                        <p className="lg:txet-xl text-sm font-normal text-darkRed pb-2">
                          title
                        </p>
                      </div>
                    </div>
                    <p>x1</p>
                  </div>
                </>
              ) : (
                <p className="text-sm text-primaryblack text-center p-5">
                  “Start Selecting The Package You Will Like To Engage With Us”
                </p>
              )}
            </div>
            <div className="bg-primaryRed p-2 rounded-b-lg flex flex-col gap-2">
              <div className="flex gap-2">
                <Calender />
                <p className="text-xs font-normal text-primaryblack">
                  30 Days Credit Payment
                </p>
              </div>
              <div className="flex gap-1">
                <Handshake />
                <p className="text-xs font-normal text-primaryblack">
                  50% Cancellation Fees
                </p>
              </div>
              <div className="flex gap-3">
                <Close />
                <p className="text-xs font-normal text-primaryblack">
                  30 Days Credit Payment
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <AddPrimaryButton
            value="Next"
            width={"100%"}
            disabled={count === 0}
            onClick={() => next()}
          />
        </div>
      </div>
    </>
  );
};

export default SelectDetails;
