import React from "react";

const Footer = () => {
  return (
    <>
      <div className="p-4 bg-black text-white">
        <p className="text-xs">©2024 All Rights Reserved</p>
      </div>
    </>
  );
};

export default Footer;
